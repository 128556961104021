@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clip-arrow {
  clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%);
}

.clip-arrow-down {
  clip-path: polygon(0 0, 50% calc(32px), 100% 0, 100% calc(100% - 32px), 50% 100%, 0 calc(100% - 32px));
}


.clip-rect-down {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px), 0 calc(100% - 32px));
}



